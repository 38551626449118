
import { defineComponent, reactive } from 'vue'

//
import SModal from '@/common/components/SModal/index.vue'
import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SCheckbox from '@/common/components/SCheckbox/index.vue'

import iClose from '@/assets/icons/Close.svg'

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import useNotifications from '@/common/composable/useNotifications'

import { InvoicesRealisation } from '@/core/realisations/Invoices.realisation'
import { SendToEmailsPayload } from '@/core/types/Invoices.types'

export default defineComponent({
    components: {
        's-modal': SModal,
        's-input': SInput,
        's-button': SButton,
        's-checkbox': SCheckbox,
        'icon-close': iClose,
    },
    props: {
        invoiceNumber: {
            type: String,
            required: true,
        },
    },
    emits: ['close'],
    setup(props, { emit }) {
        const invoices = new InvoicesRealisation()
        const notifications = useNotifications()

        const state = reactive({
            email: null as string | null,
            emails_list: [] as string[],
            should_be_send_to_owner: false,
            is_loading: false,
        })

        const state_form$ = useVuelidate(
            {
                email: { required, email },
            },
            state
        )

        const closeModal = () => {
            emit('close')
        }

        const addEmail = () => {
            state_form$.value.$touch()
            if (state_form$.value.$invalid) return

            state.emails_list.push(state.email as string)
            state.email = null
            state_form$.value.$reset()
        }

        const submit = async () => {
            try {
                state.is_loading = true

                const payload: SendToEmailsPayload = {
                    invoiceNumber: props.invoiceNumber,
                    shouldBeSentToOwner: state.should_be_send_to_owner,
                    emails: state.emails_list,
                }

                const response = await invoices.sendToEmails(payload)

                notifications.success({
                    group: 'create-module',
                    type: 'success',
                    title: response.message,
                })

                closeModal()
            } catch (error) {
                console.error(error)

                notifications.failure({
                    group: 'create-module',
                    type: 'error',
                    title: 'При отправке накладной на email произошла ошибка',
                    text: 'Пожалуйста, попробуйте ещё раз',
                })
            } finally {
                state.is_loading = false
            }
        }

        return { state, state_form$, closeModal, addEmail, submit }
    },
})
