
import { computed, defineComponent } from 'vue'
import { inject } from 'vue'

// components
import UploadedFile from '@/common/components/UploadedFile/index.vue'

export default defineComponent({
    components: { UploadedFile },
    setup() {
        const invoiceState: any = inject('invoiceState')
        const invoiceStatusesState: any = inject('invoiceStatusesState')

        const isInvoiceFiles = computed(() =>
            Boolean(invoiceState.data.invoice_files && invoiceState.data.invoice_files.length)
        )
        const isCertificate = computed(() =>
            Boolean(
                invoiceState.data.certificate_of_safety_files &&
                    invoiceState.data.certificate_of_safety_files.length
            )
        )

        return {
            invoiceState,
            invoiceStatusesState,
            //
            isInvoiceFiles,
            isCertificate,
        }
    },
})
