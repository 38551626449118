
import { defineComponent } from 'vue'

import SBreadCrumbs from '@/common/components/SBreadCrumbs/index.vue'
import InvoiceHeaderActions from '@/modules/Invoice/components/InvoiceHeaderActions/index.vue'

import iArrowLeft from '@/assets/icons/ArrowLeft.svg'

import { useRoute } from 'vue-router'
import { useInvoice } from '../../composable/useInvoice'

export default defineComponent({
    components: {
        's-bread-crumbs': SBreadCrumbs,
        'invoice-header-actions': InvoiceHeaderActions,
        'icon-arrow-left': iArrowLeft,
    },
    setup() {
        const route = useRoute()

        const breadcrumbsLinks = [
            {
                id: 1,
                name: 'LTL накладные',
                path: '/history',
            },
            {
                id: 2,
                name: route.params.invoice_id,
                path: `/invoice/${route.params.invoice_id}`,
            },
        ]

        const { invoiceState } = useInvoice()

        return { breadcrumbsLinks, invoiceState }
    },
})
