
import { AdditionalServicesFromAPIT, InvoiceNewServicesT } from '@/core/types/common.types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    props: {
        title: {
            type: String,
            default: 'Доп. услуги',
        },
        additionalServices: {
            type: [Object, null] as PropType<AdditionalServicesFromAPIT | null>,
            default: null,
        },
        newAdditionalServices: {
            type: Array as PropType<InvoiceNewServicesT>,
            default: () => [],
        },
    },
})
