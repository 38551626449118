
import { defineComponent, onMounted, provide } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'

import InvoiceHeader from '@/modules/Invoice/components/InvoiceHeader/index.vue'
import InvoiceBasic from '@/modules/Invoice/components/InvoiceBasic/index.vue'
import InvoiceAdditional from '@/modules/Invoice/components/InvoiceAdditional/index.vue'
import InvoiceInsurance from '@/modules/Invoice/components/InvoiceInsurance/index.vue'
import InvoiceDestinations from '@/modules/Invoice/components/InvoiceDestinations/index.vue'
import SendToEmailModal from '@/modules/Invoice/components/SendToEmailModal/index.vue'

// print
import InvoiceDocument from '@/modules/DocumentsModule/components/InvoiceDocument/index.vue'

import { useRoute } from 'vue-router'
import { useInvoice } from '@/modules/Invoice/composable/useInvoice'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

import { useTitle } from 'vue-composable'

export default defineComponent({
    components: {
        's-loader': SLoader,
        'invoice-header': InvoiceHeader,
        'invoice-basic': InvoiceBasic,
        'invoice-additional': InvoiceAdditional,
        'invoice-insurance': InvoiceInsurance,
        'invoice-destinations': InvoiceDestinations,
        'send-to-email-modal': SendToEmailModal,
        'invoice-document': InvoiceDocument,
    },
    setup() {
        const route = useRoute()
        const invoice_id: string = route.params.invoice_id as string

        useTitle(`LTL накладная ${invoice_id}`)

        const notifications = useNotifications()

        const {
            loadInvoice,
            invoiceState,
            invoiceStatusesState,
            isSendToEmail,
            cancelSendToEmail,
            invoicePlacesState,
        } = useInvoice()

        const loadInvoiceHandler = async () => {
            try {
                await loadInvoice(invoice_id)

                useTitle(`LTL накладная ${invoiceState.data.invoice_number}`)
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка получения накладной',
                    },
                    error
                )
            }
        }

        onMounted(() => {
            loadInvoiceHandler()
        })

        provide('invoiceState', invoiceState)
        provide('invoiceStatusesState', invoiceStatusesState)

        return {
            invoiceState,
            invoicePlacesState,
            invoice_id,
            isSendToEmail,
            cancelSendToEmail,
        }
    },
})
