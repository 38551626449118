
import { computed, defineComponent, onMounted, ref } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'

import iRefresh from '@/assets/icons/Refresh.svg'

import iStatusWaiting from '@/assets/icons/statuses/StatusWaiting.svg'
import iStatusInProgress from '@/assets/icons/statuses/StatusInProgress.svg'
import iStatusCompleted from '@/assets/icons/statuses/StatusCompleted.svg'
import iStatusCanceled from '@/assets/icons/statuses/StatusCanceled.svg'

import { useAsyncFetch } from '@/common/composable/useAsyncFetch'
import { useContainer } from '@/common/composable/useContainer'

import { InvoicesRealisation } from '@/core/realisations/Invoices.realisation'
import { LTLInvoiceStatusesResponseT } from '@/core/types/Responses/Invoices.responses'

import {
    LTL_STATUS_WAITING_TOKEN,
    LTL_STATUS_IN_PROGRESS_TOKEN,
    LTL_STATUS_COMPLETED_TOKEN,
    LTL_STATUS_CANCELED_TOKEN,
} from '@/core/constants/LTL.constants'
import { useInvoice } from '../../composable/useInvoice'
import { LTLInvoiceStatusT } from '@/core/types/LTL.types'

export default defineComponent({
    components: {
        's-loader': SLoader,
        'icon-refresh': iRefresh,
        'icon-status-waiting': iStatusWaiting,
        'icon-status-in-progress': iStatusInProgress,
        'icon-status-completed': iStatusCompleted,
        'icon-status-canceled': iStatusCanceled,
    },
    setup() {
        const isSuccess = ref(false)

        const invoicesInterface = useContainer<InvoicesRealisation>('invoices')
        const invoiceStatuses = useAsyncFetch<LTLInvoiceStatusesResponseT>(
            invoicesInterface.getLTLInvoiceStatuses
        )

        const { invoiceState, invoiceStatusesState } = useInvoice()

        const loadInvoiceStatusesHistory = async (invoice_number: string) => {
            await invoiceStatuses.asyncFetch(invoice_number)

            if (typeof invoiceStatuses.state.data === 'object') {
                // @ts-ignore
                invoiceStatusesState.client_sla_date = invoiceStatuses.state.data.client_sla_date
                isSuccess.value = true
            } else {
                isSuccess.value = false
            }
        }

        const predictionDate = computed(() => {
            if (!invoiceState || !invoiceState.data || !invoiceState.data.delivery_date_prediction)
                return '-'

            return invoiceState.data.delivery_date_prediction.split(' ')[0]
        })
        const predictionTime = computed(() => {
            if (!invoiceState || !invoiceState.data || !invoiceState.data.delivery_date_prediction)
                return '-'

            return invoiceState.data.delivery_date_prediction.split(' ')[1]
        })
        const getStatusName = (status: LTLInvoiceStatusT) => {
            return status.status_code === 211 && status.state !== 'completed'
                ? 'Прогнозируемая дата доставки'
                : status.status_name
        }

        onMounted(() => {
            if (invoiceState.data && 'invoice_number' in invoiceState.data) {
                loadInvoiceStatusesHistory(invoiceState.data.invoice_number)
            }
        })

        return {
            invoiceState,
            invoiceStatuses,
            isSuccess,
            LTL_STATUS_WAITING_TOKEN,
            LTL_STATUS_IN_PROGRESS_TOKEN,
            LTL_STATUS_COMPLETED_TOKEN,
            LTL_STATUS_CANCELED_TOKEN,
            //
            predictionDate,
            predictionTime,
            getStatusName,
        }
    },
})
