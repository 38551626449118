import { reactive, ref } from 'vue'

import { InvoicesRealisation } from '@/core/realisations/Invoices.realisation'
import { LTLFullInvoiceT } from '@/core/types/LTL.types'
import {
    LTLInvoiceForPrintResponseT,
    LTLInvoicePlacesForPrintResponseT,
} from '@/core/types/Responses/Invoices.responses'

import { useFile } from '@/common/composable/useFile'

type InvoiceStateType = {
    state: {
        is_loading: boolean
    }
    data: LTLFullInvoiceT | LTLInvoiceForPrintResponseT | LTLInvoicePlacesForPrintResponseT | null
    data_print?:
        | LTLFullInvoiceT
        | LTLInvoiceForPrintResponseT
        | LTLInvoicePlacesForPrintResponseT
        | null
}

const invoiceState: InvoiceStateType = reactive({
    state: {
        is_loading: false,
    },
    data: null,
    data_print: null,
})

const invoiceStatusesState = reactive({
    state: {
        is_loading: false,
    },
    data: null,
    client_sla_date: null as string | null,
})

const invoiceCancel: InvoiceStateType = reactive({
    state: {
        is_loading: false,
    },
    data: null,
})

const invoicePlacesState: InvoiceStateType = reactive({
    state: {
        is_loading: false,
    },
    data: null,
})

const isSendToEmail = ref(false)
const isPlacesPrint = ref(false)

export const useInvoice = () => {
    const invoicesRealisation = new InvoicesRealisation()
    const { saveFile } = useFile()

    const loadInvoice = async (invoice_id: string) => {
        try {
            invoiceState.state.is_loading = true
            const invoiceResponse = await invoicesRealisation.getLTLInvoice(invoice_id)
            invoiceState.data = invoiceResponse.data
        } catch (error) {
            console.error(error)
        } finally {
            invoiceState.state.is_loading = false
        }
    }

    const loadInvoiceForPrint = async (invoice_id: string) => {
        try {
            invoiceState.state.is_loading = true

            const invoiceResponse = await invoicesRealisation.getInvoiceForPrint(invoice_id)
            invoiceState.data_print = invoiceResponse.data
        } catch (error) {
            console.error(error)
        } finally {
            invoiceState.state.is_loading = false
        }
    }

    const loadInvoicePlaces = async (invoice_id: number) => {
        if (invoicePlacesState.state.is_loading) return

        try {
            isPlacesPrint.value = true

            invoicePlacesState.state.is_loading = true

            const invoiceResponse = await invoicesRealisation.getInvoicePlaces(invoice_id)

            saveFile(invoiceResponse, invoiceState.data.invoice_number)
        } catch (error) {
            console.error(error)
        } finally {
            invoicePlacesState.state.is_loading = false
        }
    }

    const cancelInvoice = async (invoice_id: number) => {
        try {
            invoiceCancel.state.is_loading = true
            await invoicesRealisation.cancelInvoice(invoice_id)
        } finally {
            invoiceCancel.state.is_loading = false
        }
    }

    const startSendToEmail = () => {
        isSendToEmail.value = true
    }

    const cancelSendToEmail = () => {
        isSendToEmail.value = false
    }

    return {
        loadInvoice,
        loadInvoiceForPrint,
        loadInvoicePlaces,
        invoiceState,
        invoiceStatusesState,
        invoiceCancel,
        invoicePlacesState,
        isPlacesPrint,
        cancelInvoice,
        isSendToEmail,
        startSendToEmail,
        cancelSendToEmail,
    }
}
