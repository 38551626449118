
import { computed, defineComponent, onBeforeMount, PropType, reactive } from 'vue'
import { PVZT } from '@/core/types/PVZ.types'
import { PVZRealisation } from '@/core/realisations/PVZ.realisation'
import toPhoneMixin from '@/common/mixins/toPhone.mixin'

import InvoiceServices from '@/modules/Invoice/components/InvoiceServices/index.vue'
import { AdditionalServicesFromAPIT, InvoiceNewServicesT } from '@/core/types/common.types'

export default defineComponent({
    components: {
        'invoice-services': InvoiceServices,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        destination: {},
        distributionCenterId: {
            type: Number,
            default: null,
        },
        isPvz: {
            type: Boolean,
            default: false,
        },
        additionalServices: {
            type: [Object, null] as PropType<AdditionalServicesFromAPIT | null>,
            default: null,
        },
        newAdditionalServices: {
            type: Array as PropType<InvoiceNewServicesT>,
            default: () => [],
        },
    },
    setup(props) {
        const state = reactive({
            pvz: null as PVZT | null,
            is_loading: false,
            is_failed: false,
        })

        const pvzRealisation = new PVZRealisation()

        const loadPVZ = async () => {
            try {
                if (!props.distributionCenterId || !props.isPvz) return
                state.is_loading = true

                const response = await pvzRealisation.getPVZ(props.distributionCenterId)
                state.pvz = response.data

                state.is_failed = false
            } catch (error) {
                console.log(error)
                state.is_failed = true
            } finally {
                state.is_loading = false
            }
        }

        const getPVZInfo = computed(() => {
            let result = ''

            if (state.is_loading) {
                result = 'Загрузка...'
            } else if (state.is_failed) {
                result = 'Ошибка загрузки ПВЗ.'
            } else if (state.pvz) {
                if (state.pvz.phone) result += ` ${toPhoneMixin.methods.toPhone(state.pvz.phone)}`
            }

            return result
        })

        onBeforeMount(loadPVZ)

        const isCanShowNewAdditionalServices = computed(() => {
            if (!props.newAdditionalServices || !props.newAdditionalServices.length) return false
            return true
        })

        const isCanShowAdditionalServices = computed(() => {
            if (!props.additionalServices) return false
            if (isCanShowNewAdditionalServices.value) return false

            return false
        })

        return {
            state,
            getPVZInfo,
            loadPVZ,
            isCanShowNewAdditionalServices,
            isCanShowAdditionalServices,
        }
    },
})
