<script>
import { defineComponent } from 'vue'
import { inject } from 'vue'

export default defineComponent({
    setup() {
        const invoiceState = inject('invoiceState')
        return { invoiceState }
    },
})
</script>

<template>
    <div class="InvoiceInsurance">
        <h2 class="InvoiceInsurance__title">Страхование груза</h2>

        <div class="InvoiceInsurance__body">
            <div class="body__block">
                <p class="block__subtext">Название груза</p>
                <p class="block__text">{{ invoiceState.data.cargo_name || 'Не указано' }}</p>
            </div>

            <div class="body__block">
                <p class="block__subtext">Объявленная стоимость</p>
                <p class="block__text">{{ invoiceState.data.declared_price || 0 }} т</p>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped src="./index.scss" />
