
import { defineComponent } from 'vue'

import InvoiceBasicDelivery from '@/modules/Invoice/components/InvoiceBasicDelivery/index.vue'
import InvoiceBasicDeliveryHistory from '@/modules/Invoice/components/InvoiceBasicDeliveryHistory/index.vue'

import { useInvoice } from '../../composable/useInvoice'

export default defineComponent({
    components: {
        'invoice-basic-delivery': InvoiceBasicDelivery,
        'invoice-basic-delivery-history': InvoiceBasicDeliveryHistory,
    },
    setup() {
        const { invoiceState } = useInvoice()
        return { invoiceState }
    },
})
