
import { computed, defineComponent, reactive } from 'vue'

import SIconButton from '@/common/components/SIconButton/index.vue'
import SModal from '@/common/components/SModal/index.vue'
import CancelInvoice from '@/modules/Invoice/components/CancelInvoice/index.vue'

import iEmail from '@/assets/icons/Mail.svg'
import iPrint from '@/assets/icons/Print.svg'
import iImage from '@/assets/icons/Image.svg'
import iDownload from '@/assets/icons/Download.svg'
import iCancel from '@/assets/icons/Cancel.svg'
import iVectorLeft from '@/assets/icons/Number.svg'
import iEdit from '@/assets/icons/Edit.svg'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

import { useInvoice } from '../../composable/useInvoice'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
    components: {
        's-icon-button': SIconButton,
        's-modal': SModal,
        'cancel-invoice': CancelInvoice,
        'icon-email': iEmail,
        'icon-print': iPrint,
        'icon-image': iImage,
        'icon-download': iDownload,
        'icon-cancel': iCancel,
        'icon-vector-left': iVectorLeft,
        'icon-edit': iEdit,
    },
    setup() {
        const modal_state = reactive({
            is_opened: false,
        })

        const openModal = () => {
            modal_state.is_opened = true
        }

        const closeModal = () => {
            modal_state.is_opened = false
        }

        const notifications = useNotifications()

        const route = useRoute()
        const router = useRouter()
        const invoiceId = route.params.invoice_id as string

        const {
            invoiceState,
            loadInvoicePlaces,
            loadInvoiceForPrint,
            invoiceCancel,
            invoicePlacesState,
            cancelInvoice,
            startSendToEmail,
        } = useInvoice()

        const printInvoice = async () => {
            try {
                await loadInvoiceForPrint(invoiceState.data.id)

                if (!invoiceState.data_print) return
                setTimeout(window.print, 500)
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка при печати накладной',
                    },
                    error
                )
            }
        }

        const printPlaces = async () => {
            if (!invoiceState.data) return

            try {
                if ('invoice_number' in invoiceState.data) {
                    await loadInvoicePlaces(invoiceState.data.id)
                }
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка печати мест по накладной',
                    },
                    error
                )
            }
        }

        const cancelInvoiceHandler = async () => {
            try {
                await cancelInvoice(Number(invoiceId))

                notifications.success({
                    group: NOTIFICATIONS_GROUP,
                    type: 'success',
                    title: 'Накладная отменена',
                })

                await router.replace('/')
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка отмены накладной',
                    },
                    error
                )
            }
        }

        const isCanUpdateCargo = computed(() => {
            if (!invoiceState.data || !invoiceState.data.status) return false

            return invoiceState.data.status.id < 7
        })

        return {
            invoiceState,
            printInvoice,
            printPlaces,
            invoiceCancel,
            invoicePlacesState,
            cancelInvoiceHandler,
            modal_state,
            openModal,
            closeModal,
            startSendToEmail,
            isCanUpdateCargo,
        }
    },
})
