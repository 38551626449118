
import { defineComponent, inject } from 'vue'

import InvoiceDestination from '@/modules/Invoice/components/InvoiceDestination/index.vue'

export default defineComponent({
    components: {
        'invoice-destination': InvoiceDestination,
    },
    setup() {
        const invoiceState = inject('invoiceState')
        return { invoiceState }
    },
})
