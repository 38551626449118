
import { defineComponent } from 'vue'
import { inject } from 'vue'

import { PLACES_TOKENS } from '@/core/constants/common.constants'

export default defineComponent({
    setup() {
        const invoiceState = inject('invoiceState')

        const getCostPrediction = (cost_prediction: number | null, status_code: number) => {
            if (cost_prediction === -1) {
                return 'Нет в биллинге'
            } else if (cost_prediction === null) {
                return 'Будет доступно после обработки груза'
            } else {
                if ([204, 212, 503].includes(status_code)) {
                    return ''
                } else if (status_code === 211) {
                    return cost_prediction + ' ₸'
                } else {
                    return 'от ' + cost_prediction + ' ₸'
                }
            }
        }

        return { invoiceState, PLACES_TOKENS, getCostPrediction }
    },
})
